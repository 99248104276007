import React from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { bounceIn, bounceInDown, slideInRight } from "react-animations";

const bounceInAnimattion = keyframes`${bounceIn}`;
const BounceInDiv = styled.div`
  animation: 1.5s ${bounceInAnimattion};
`;

const bounceInDownAnimattion = keyframes`${bounceInDown}`;
const BounceInDownDiv = styled.div`
  animation: 1.5s ${bounceInDownAnimattion};
`;

const slideInRightAnimation = keyframes`${slideInRight}`;
const SlideInRightDiv = styled.div`
  animation: 1s ${slideInRightAnimation};
`;

function Free() {
  return (
    <div className="container">
      <div className="content">
        <BounceInDownDiv>
          <img src="/logo/1.jpeg" alt="Nutrixpress" />
        </BounceInDownDiv>
        <SlideInRightDiv>
          <h1>
            Share Your Experience & Get Your <span>FREE BOTTLE!</span>
          </h1>
          <div className="body-text">
            <p>
              Have you recently purchased our supplements from Amazon? We hope you had a positive
              experience and would love for you to share it with us to redeem a <u>FREE PRODUCT</u>{" "}
              at no charge! YES, you read that right!
            </p>
            <p>
              In order to qualify, you must have actively used our product purchased via Amazon.com
              for more than 5 days and agree to submit a survey to share your feedback.
            </p>
            <p>
              The promotion is limited to one <u>FREE PRODUCT</u> type per household. Please allow
              2-3 weeks for delivery. Availability is limited to United States Customers ONLY. Offer
              is limited, and is processed on a first come, first serve basis.
            </p>
          </div>
        </SlideInRightDiv>
        <BounceInDiv>
          <NavLink to="/form">
            <button>CLAIM YOUR FREE BOTTLE</button>
          </NavLink>
        </BounceInDiv>
      </div>
    </div>
  );
}

export default Free;
