import React from "react";

function Home() {
  return (
    <div className="home">
      <img className="breathing" src="/logo/1.jpeg" alt="Nutrixpress" />
      <h1>Coming Soon</h1>
    </div>
  );
}

export default Home;
