import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { bounceIn, bounceInDown, slideInRight } from "react-animations";

const serverURL = process.env.REACT_APP_SERVER_URL;

const bounceInAnimattion = keyframes`${bounceIn}`;
const BounceInDiv = styled.div`
  animation: 1.5s ${bounceInAnimattion};
`;

const bounceInDownAnimattion = keyframes`${bounceInDown}`;
const BounceInDownDiv = styled.div`
  animation: 1.5s ${bounceInDownAnimattion};
`;

const slideInRightAnimation = keyframes`${slideInRight}`;
const SlideInRightDiv = styled.div`
  animation: 1s ${slideInRightAnimation};
`;

function Form() {
  const [form, setForm] = useState({
    tag: "NUTRIXPRESS",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    zipcode: "",
    product: "",
    time: "",
    experience: "",
  });

  const [state, setState] = useState({
    fetching: false,
    success: false,
    error: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setState({ ...state, fetching: true });

    fetch(serverURL + "/add-contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: form,
      }),
    })
      .then(response => response.json())
      .then(data => {
        data[0]
          ? setState({ ...state, fetching: true, success: data[0] })
          : setState({ ...state, fetching: true, error: true });
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  const handleChange = async e => {
    const target = e.target,
      value = target.value,
      name = target.name;
    setForm({ ...form, [name]: value });
  };

  return (
    <div className="container form">
      <BounceInDiv className="cont-back">
        <NavLink className="back" to="/free">
          <div alt="back"></div>
        </NavLink>
      </BounceInDiv>
      <BounceInDownDiv>
        <h1>Free Bottle Event</h1>
      </BounceInDownDiv>
      <SlideInRightDiv>
        {state.fetching ? (
          <div className="form-loader">
            {!state.success && !state.error ? (
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : !state.success ? (
              <h3>Oooopsss... Something went wrong!</h3>
            ) : !state.error ? (
              <h3>Thank you for your time, enjoy!</h3>
            ) : (
              ""
            )}
          </div>
        ) : (
          <form onSubmit={e => handleSubmit(e)} method="POST">
            <fieldset>
              <div>
                <label>
                  {" "}
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="firstName"
                  value={form.firstName}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label>
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="lastName"
                  value={form.lastName}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label>
                  Email <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="email"
                  value={form.email}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  value={form.phone}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label>
                  Shipping Addres <span>*</span>
                </label>
                <textarea
                  rows="5"
                  required
                  name="address"
                  value={form.address}
                  onChange={e => handleChange(e)}
                ></textarea>
                <label>
                  Zipcode <span>*</span>
                </label>
                <input
                  required
                  type="text"
                  name="zipcode"
                  value={form.zipcode}
                  onChange={e => handleChange(e)}
                ></input>{" "}
              </div>
              <div>
                <label>
                  What product did you purchase? <span>*</span>
                </label>
                <textarea
                  rows="5"
                  required
                  name="product"
                  value={form.product}
                  onChange={e => handleChange(e)}
                ></textarea>
              </div>
              <div>
                <label>Have you used the product for at least 5 days?</label>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="time"
                      onChange={() => setForm({ ...form, time: "Yes" })}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="time"
                      onChange={() => setForm({ ...form, time: "No" })}
                    />
                    No
                  </label>
                </div>
              </div>
              <div>
                <label>
                  Please share your experience! <span>*</span>
                </label>
                <textarea
                  rows="5"
                  required
                  name="experience"
                  value={form.experience}
                  onChange={e => handleChange(e)}
                ></textarea>
              </div>
              <div>
                <button>Submit</button>
              </div>
            </fieldset>
          </form>
        )}
      </SlideInRightDiv>
    </div>
  );
}

export default Form;
